<template>
  <div>
    <div class="container-fluid home">
      <!-- sungai -->
      <div v-if="$can('sungai.resume', 'sungai')" class="row mb-5">
        <div class="col-md-12 col-sm-12 d-flex">
          <b-card title="Resume Sungai" class="flex-fill text-center mb-0">
            <highcharts :options="chartSungaix"></highcharts>
            <!-- <DxChart
              id="chartSungai"
              :rotated="true"
              :data-source="resumeChartSungaiDataSource()"
            >
              <DxSeries
                argument-field="nama"
                value-field="total"
                type="bar"
                color="#030f6bb3"
              />
              <DxTooltip
                :enabled="true"
                :shared="true"
                :customize-tooltip="customizeTooltip"
              />
              <DxLegend :visible="false" />
            </DxChart> -->
          </b-card>
        </div>
      </div>
      <!-- pah -->
      <div
        v-if="$can('penampunganAirHujan.resume', 'penampunganAirHujan')"
        class="row mb-5"
      >
        <!-- <div class="col-md-6 col-sm-12 d-flex">
          <dx-data-grid :ref="gridPahName" :height="500" :width="'100%'">
            <dx-column-chooser mode="select" />
          </dx-data-grid>
        </div> -->
        <div class="col-md-12 col-sm-12 d-flex">
          <b-card
            title="Resume Penampungan Air Hujan"
            class="flex-fill text-center mb-0"
          >
          <highcharts :options="chartPAH"></highcharts>
            <!-- <DxChart
              id="chartPah"
              :rotated="true"
              :data-source="resumeChartPahDataSource()"
            >
              <DxSeries
                argument-field="nama"
                value-field="total"
                type="bar"
                color="#030f6bb3"
              />
              <DxTooltip
                :enabled="true"
                :shared="true"
                :customize-tooltip="customizeTooltip"
              />
              <DxLegend :visible="false" />
            </DxChart> -->
          </b-card>
        </div>
      </div>

      <!-- mata air -->
      <div v-if="$can('mataAir.resume', 'mataAir')" class="row mb-5">
        <!-- <div class="col-md-6 col-sm-12 d-flex">
          <dx-data-grid :ref="gridMataAirName" :height="500" :width="'100%'">
            <dx-column-chooser mode="select" />
          </dx-data-grid>
        </div> -->
        <div class="col-md-12 col-sm-12 d-flex">
          <b-card title="Resume Mata Air" class="flex-fill text-center mb-0">
            <highcharts :options="chartMataAir"></highcharts>
            <!-- <DxChart
              id="chartMataAir"
              :rotated="true"
              :data-source="resumeChartMataAirDataSource()"
            >
              <DxSeries
                argument-field="nama"
                value-field="total"
                type="bar"
                color="#030f6bb3"
              />
              <DxTooltip
                :enabled="true"
                :shared="true"
                :customize-tooltip="customizeTooltip"
              />
              <DxLegend :visible="false" />
            </DxChart> -->
          </b-card>
        </div>
      </div>

      <!-- tampungan -->
      <div v-if="$can('tampungan.resume', 'tampungan')" class="row mb-5">
        <!-- <div class="col-md-6 col-sm-12 d-flex">
          <dx-data-grid :ref="gridTampunganName" :height="500" :width="'100%'">
            <dx-column-chooser mode="select" />
          </dx-data-grid>
        </div>
        <div class="col-md-6 col-sm-12 d-flex">
          <b-card title="Resume Tampungan" class="flex-fill text-center mb-0">
            <DxChart
              id="chartTampungan"
              :rotated="true"
              :data-source="resumeChartTampunganDataSource()"
            >
              <DxSeries
                argument-field="nama"
                value-field="total"
                type="bar"
                color="#030f6bb3"
              />
              <DxTooltip
                :enabled="true"
                :shared="true"
                :customize-tooltip="customizeTooltip"
              />
              <DxLegend :visible="false" />
            </DxChart>
          </b-card>
        </div> -->

        <div class="col-md-12 col-sm-12 d-flex mb-2">
          <b-card title="Resume Waduk" class="flex-fill text-center mb-0">
            <highcharts :options="chartWaduk"></highcharts>
          </b-card>
        </div>
        <div class="col-md-12 col-sm-12 d-flex mb-2">
          <b-card title="Resume Situ" class="flex-fill text-center mb-0">
            <highcharts :options="chartSitu"></highcharts>
          </b-card>
        </div>
        <div class="col-md-12 col-sm-12 d-flex mb-2">
          <b-card title="Resume Danau" class="flex-fill text-center mb-0">
            <highcharts :options="chartDanau"></highcharts>
          </b-card>
        </div>
        <div class="col-md-12 col-sm-12 d-flex mb-2">
          <b-card title="Resume Embung" class="flex-fill text-center mb-0">
            <highcharts :options="chartEmbung"></highcharts>
          </b-card>
        </div>
      </div>
      
      <!-- Sumur -->
      <div v-if="$can('sumur.resume', 'sumur')" class="row mb-5">
        <!-- <div class="col-md-6 col-sm-12 d-flex">
          <dx-data-grid :ref="gridSumurName" :height="500" :width="'100%'">
            <dx-column-chooser mode="select" />
          </dx-data-grid>
        </div> -->
        <div class="col-md-12 col-sm-12 d-flex mb-2">
          <b-card title="Resume Total Sumur" class="flex-fill text-center mb-0">
            <highcharts :options="chartSumur"></highcharts>
            <!-- <DxChart
              id="chartSumur"
              :rotated="true"
              :data-source="resumeChartSumurDataSource()"
            >
              <DxSeries
                argument-field="nama"
                value-field="total"
                type="bar"
                color="#030f6bb3"
              />
              <DxTooltip
                :enabled="true"
                :shared="true"
                :customize-tooltip="customizeTooltip"
              /> -->
              <DxLegend :visible="false" />
            </DxChart>
          </b-card>
        </div>
        <div class="col-md-12 col-sm-12 d-flex mb-2">
          <b-card title="Resume Sumur Air Baku" class="flex-fill text-center mb-0">
            <highcharts :options="chartSumurAirBaku"></highcharts>
            </b-card>
        </div>
        <div class="col-md-12 col-sm-12 d-flex">
          <b-card title="Resume Sumur JIAT" class="flex-fill text-center mb-0">
            <highcharts :options="chartSumurJIAT"></highcharts>
            </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DefaultPageData } from "@/modules/mixins/data";
import { DxChart, DxSeries, DxLegend, DxTooltip } from "devextreme-vue/chart";
import { BCard } from "bootstrap-vue";
import axiosIns from "@/libs/axios";
import VueApexCharts from "vue-apexcharts";
import { Chart } from "highcharts-vue";

export default {
  components: {
    BCard,
    DxChart,
    DxSeries,
    DxLegend,
    DxTooltip,
    apexchart: VueApexCharts,
    highcharts: Chart,
  },
  props: {
    pageName: {
      type: String,
      default: "Home",
    },
    pageSungaiName: {
      type: String,
      default: "ResumeSungai",
    },
    pagePahName: {
      type: String,
      default: "ResumePenampunganAirHujan",
    },
    pageTampunganName: {
      type: String,
      default: "ResumeTampungan",
    },
    pageMataAirName: {
      type: String,
      default: "ResumeMataAir",
    },
    pageSumurName: {
      type: String,
      default: "ResumeSumur",
    },
    title: {
      type: String,
      default: "Home",
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 200;
      },
    },
  },
  data() {
    const data = { ...DefaultPageData };
    this.$stateMerge(data, {
      // gridName: `${this.pageName}Grid`,
      // gridSungaiName: `${this.pageSungaiName}Grid`,
      gridPahName: `${this.pagePahName}Grid`,
      gridTampunganName: `${this.pageTampunganName}Grid`,
      // gridMataAirName: `${this.pageMataAirName}Grid`,
      gridSumurName: `${this.pageSumurName}Grid`,
      chartSungaix: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Sungai",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Tidak Operasi",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartSumur: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Sumur",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Air Baku",
            data: [],
            color: "#EE5373",
          },
          {
            name: "JIAT",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartMataAir: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Mata Air",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Tidak Operasi",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartPAH: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "PAH",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Tidak Operasi",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartSumurAirBaku: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Sumur",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Tidak Aktif",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Tidak Operasi",
            data: [],
            color: "#F7BC4E",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartSumurJIAT: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Sumur",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Tidak Aktif",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Tidak Operasi",
            data: [],
            color: "#F7BC4E",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartWaduk: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Waduk",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
        {
            name: "Tidak Operasi",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartSitu: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Situ",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
        {
            name: "Tidak Operasi",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartDanau: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Danau",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Tidak Operasi",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
      chartEmbung: {
        title: {
          text: "",
        },
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Embung",
            align: "high",
          },
          stackLabels: {
            enabled: true,

            formatter: function () {
              return (
                "<b>" +
                Math.round(this.total * Math.pow(10, 2)) / Math.pow(10, 2) +
                "</b>"
              );
            },

            y: -10,
          },
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        legend: {
          layout: "horizontal",
          style: {
            left: "auto",
            bottom: "auto",
            right: "100px",
            top: "100px",
          },
          backgroundColor: "#FFFFFF",
        },
        credits: {
          enabled: false,
        },
        series: [
        {
            name: "Tidak Operasi",
            data: [],
            color: "#EE5373",
          },
          {
            name: "Operasi",
            data: [],
            color: "#419FF7",
          },
        ],
      },
    });
    return data;
  },
  created() {
    const vm = this;
    vm.gridCreated();
  },
  mounted() {
    const vm = this;
    // console.log('sungai.resume', vm.$can('sungai.resume', 'sungai'))
    // console.log('penampunganAirHujan.resume', vm.$can('penampunganAirHujan.resume', 'penampunganAirHujan'))
    // console.log('tampungan.resume', vm.$can('tampungan.resume', 'tampungan'))
    // console.log('mataAir.resume', vm.$can('mataAir.resume', 'mataAir'))
    // console.log('sumur.resume', vm.$can('sumur.resume', 'sumur'))
    // vm.gridMount();
    if (vm.$can("sungai.resume", "sungai")) {
      vm.gridSungaiMount();
    }
    if (vm.$can("penampunganAirHujan.resume", "penampunganAirHujan")) {
      vm.gridPahMount();
    }
    if (vm.$can("tampungan.resume", "tampungan")) {
      vm.gridTampunganMount();
    }
    if (vm.$can("mataAir.resume", "mataAir")) {
      vm.gridMataAirMount();
    }
    if (vm.$can("sumur.resume", "sumur")) {
      vm.gridSumurMount();
    }
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${pointInfo.argumentText}: ${pointInfo.points[0].valueText}</div></div>`,
      };
    },
    resumeChartSungaiDataSource() {
      // console.log("resumeChartSungaiDataSource");
      return {
        store: this.$aspnet.createStore({
          key: "id",
          loadUrl: "api/data/balai",
          loadMethod: "POST",
          loadParams: {
            orderBy: "master_balai.id",
            groupBy: "master_balai.id",
            select: ["master_balai.id", "master_balai.nama"],
            addSelect: ["COUNT(distinct sungai.id) AS total"],
            join: [
              {
                on: ["master_balai.id", "=", "sungai.balai_id"],
                type: "join",
                table: "sungai",
                clousure: {
                  where: ["sungai.deleted_at", "isnull", null],
                },
              },
            ],
          },
          onBeforeSend(operation, ajaxSettings) {
            if (operation === "load") {
              ajaxSettings.headers = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              };
              ajaxSettings.data = {
                data: JSON.stringify(ajaxSettings.data),
              };
            }
          },
        }),
        paginate: false,
        pageSize: 1000,
      };
    },
    resumeChartPahDataSource() {
      // console.log("resumeChartPahDataSource");
      return {
        store: this.$aspnet.createStore({
          key: "id",
          loadUrl: "api/data/balai",
          loadMethod: "POST",
          loadParams: {
            orderBy: "master_balai.id",
            groupBy: "master_balai.id",
            select: ["master_balai.id", "master_balai.nama"],
            addSelect: ["COUNT(distinct pah.id) AS total"],
            join: [
              {
                on: ["master_balai.id", "=", "pah.balai_id"],
                type: "join",
                table: "pah",
                clousure: {
                  where: ["pah.deleted_at", "isnull", null],
                },
              },
            ],
          },
          onBeforeSend(operation, ajaxSettings) {
            if (operation === "load") {
              ajaxSettings.headers = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              };
              ajaxSettings.data = {
                data: JSON.stringify(ajaxSettings.data),
              };
            }
          },
        }),
        paginate: false,
        pageSize: 1000,
      };
    },
    resumeChartTampunganDataSource() {
      // console.log("resumeChartTampunganDataSource");
      return {
        store: this.$aspnet.createStore({
          key: "id",
          loadUrl: "api/data/balai",
          loadMethod: "POST",
          loadParams: {
            orderBy: "master_balai.id",
            groupBy: "master_balai.id",
            select: ["master_balai.id", "master_balai.nama"],
            addSelect: ["COUNT(tampungan.id) AS total"],
            join: [
              {
                on: ["master_balai.id", "=", "tampungan.balai_id"],
                type: "join",
                table: "tampungan",
                clousure: {
                  where: ["tampungan.deleted_at", "isnull", null],
                },
              },
            ],
          },
          onBeforeSend(operation, ajaxSettings) {
            if (operation === "load") {
              ajaxSettings.headers = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              };
              ajaxSettings.data = {
                data: JSON.stringify(ajaxSettings.data),
              };
            }
          },
        }),
        paginate: false,
        pageSize: 1000,
      };
    },
    resumeChartMataAirDataSource() {
      // console.log("resumeChartMataAirDataSource");
      return {
        store: this.$aspnet.createStore({
          key: "id",
          loadUrl: "api/data/balai",
          loadMethod: "POST",
          loadParams: {
            orderBy: "master_balai.id",
            groupBy: "master_balai.id",
            select: ["master_balai.id", "master_balai.nama"],
            addSelect: ["COUNT(mata_air.id) AS total"],
            join: [
              {
                on: ["master_balai.id", "=", "mata_air.balai_id"],
                type: "join",
                table: "mata_air",
                clousure: {
                  where: ["mata_air.deleted_at", "isnull", null],
                },
              },
            ],
          },
          onBeforeSend(operation, ajaxSettings) {
            if (operation === "load") {
              ajaxSettings.headers = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              };
              ajaxSettings.data = {
                data: JSON.stringify(ajaxSettings.data),
              };
            }
          },
        }),
        paginate: false,
        pageSize: 1000,
      };
    },
    resumeChartSumurDataSource() {
      // console.log("resumeChartSumurDataSource");
      return {
        store: this.$aspnet.createStore({
          key: "id",
          loadUrl: "api/data/balai",
          loadMethod: "POST",
          loadParams: {
            orderBy: "master_balai.id",
            groupBy: "master_balai.id",
            select: ["master_balai.id", "master_balai.nama"],
            addSelect: ["COUNT(sumur.id) AS total"],
            join: [
              {
                on: ["master_balai.id", "=", "sumur.balai_id"],
                type: "join",
                table: "sumur",
                clousure: {
                  where: ["sumur.deleted_at", "isnull", null],
                },
              },
            ],
          },
          onBeforeSend(operation, ajaxSettings) {
            if (operation === "load") {
              ajaxSettings.headers = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              };
              ajaxSettings.data = {
                data: JSON.stringify(ajaxSettings.data),
              };
            }
          },
        }),
        paginate: false,
        pageSize: 1000,
      };
    },
    gridSungaiMount() {
      const vm = this;
      axiosIns
        .get(`${vm.formSettingRouteReq + vm.pageSungaiName}.json`, {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        })
        .then((resp) => {
          try {
            // console.log("sungaimount", resp);
            if (!resp.data.success) {
              throw "konfigurasi form tidak ditemukan";
            }

            //get data for chart
            axiosIns
              .post(
                `${process.env.VUE_APP_API_URL}/${resp.data.data.gridOption.dataSource.loadUrl}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  data: resp.data.data.gridOption.dataSource.loadParams,
                }
              )
              .then((result) => {
                try {
                  if (!result.data.success) {
                    throw "konfigurasi form tidak ditemukan";
                  }
                  let data = result.data.data;
                  this.chartSungaix.xAxis.categories = data.map((x) => x.nama);
                  let tidak_operasi = [];
                  let operasi = [];
                  // vm.chartSungai.series[0].data

                  data.forEach((value, index) => {
                    tidak_operasi.push(
                      parseInt(value.total_sungai_tidak_operasi)
                    );
                    operasi.push(parseInt(value.total_sungai_operasi));
                  });

                  vm.chartSungaix.series[0].data = tidak_operasi;
                  vm.chartSungaix.series[1].data = operasi;
                } catch (e) {
                  vm.msgShow(e, "error");
                }
              });

            //init grid table
            //vm.gridSungaiConfigInit(resp.data.data);
          } catch (e) {
            vm.msgShow(e, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 404) {
            vm.msgShow("konfigurasi form tidak ditemukan", "error");
          } else if (error.response.data !== undefined) {
            vm.msgShow(error.response.data.message, "error");
          }
        });
    },
    gridSungaiConfigInit(config) {
      const vm = this;
      vm.grid = vm.$refs[vm.gridSungaiName].instance;
      vm.gridConfig = config;
      vm.grid.option(config.gridOption);

      if (
        config.gridOption !== undefined &&
        config.gridOption.dataSource !== undefined
      ) {
        vm.grid.option(
          "dataSource.store",
          vm.$aspnet.createStore({
            key: config.gridOption.dataSource.key,
            loadUrl: `${process.env.VUE_APP_API_URL}/${config.gridOption.dataSource.loadUrl}`,
            loadMethod:
              config.gridOption.dataSource.loadMethod !== undefined
                ? config.gridOption.dataSource.loadMethod
                : "GET",
            loadParams: config.gridOption.dataSource.loadParams,
            onBeforeSend(operation, ajaxSettings) {
              if (operation === "load") {
                ajaxSettings.headers = {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                };
                ajaxSettings.data = {
                  data: JSON.stringify(ajaxSettings.data),
                };
              }
            },
          })
        );
      }
    },
    gridPahMount() {
      const vm = this;
      axiosIns
        .get(`${vm.formSettingRouteReq + vm.pagePahName}.json`, {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        })
        .then((resp) => {
          try {
            // console.log("resp", resp);
            if (!resp.data.success) {
              throw "konfigurasi form tidak ditemukan";
            }

            //get data for chart
            axiosIns
              .post(
                `${process.env.VUE_APP_API_URL}/${resp.data.data.gridOption.dataSource.loadUrl}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  data: resp.data.data.gridOption.dataSource.loadParams,
                }
              )
              .then((result) => {
                try {
                  if (!result.data.success) {
                    throw "konfigurasi form tidak ditemukan";
                  }
                  let data = result.data.data;
                  this.chartPAH.xAxis.categories = data.map(
                    (x) => x.nama
                  );
                  let tidak_operasi = [];
                  let operasi = [];
                  // vm.chartSungai.series[0].data

                  data.forEach((value, index) => {
                    tidak_operasi.push(parseInt(value.total_pah_tidak_operasi));
                    operasi.push(parseInt(value.total_pah_operasi));
                  });

                  vm.chartPAH.series[0].data = tidak_operasi;
                  vm.chartPAH.series[1].data = operasi;
                } catch (e) {
                  vm.msgShow(e, "error");
                }
              });
            // vm.gridPahConfigInit(resp.data.data);
          } catch (e) {
            vm.msgShow(e, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 404) {
            vm.msgShow("konfigurasi form tidak ditemukan", "error");
          } else if (error.response.data !== undefined) {
            vm.msgShow(error.response.data.message, "error");
          }
        });
    },
    gridPahConfigInit(config) {
      const vm = this;
      vm.grid = vm.$refs[vm.gridPahName].instance;
      vm.gridConfig = config;
      vm.grid.option(config.gridOption);

      if (
        config.gridOption !== undefined &&
        config.gridOption.dataSource !== undefined
      ) {
        vm.grid.option(
          "dataSource.store",
          vm.$aspnet.createStore({
            key: config.gridOption.dataSource.key,
            loadUrl: `${process.env.VUE_APP_API_URL}/${config.gridOption.dataSource.loadUrl}`,
            loadMethod:
              config.gridOption.dataSource.loadMethod !== undefined
                ? config.gridOption.dataSource.loadMethod
                : "GET",
            loadParams: config.gridOption.dataSource.loadParams,
            onBeforeSend(operation, ajaxSettings) {
              if (operation === "load") {
                ajaxSettings.headers = {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                };
                ajaxSettings.data = {
                  data: JSON.stringify(ajaxSettings.data),
                };
              }
            },
          })
        );
      }
    },
    gridTampunganMount() {
      const vm = this;
      axiosIns
        .get(`${vm.formSettingRouteReq + vm.pageTampunganName}.json`, {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        })
        .then((resp) => {
          try {
            // console.log("resp", resp);
            if (!resp.data.success) {
              throw "konfigurasi form tidak ditemukan";
            }
            //get data for chart
            axiosIns
              .post(
                `${process.env.VUE_APP_API_URL}/${resp.data.data.gridOption.dataSource.loadUrl}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  data: resp.data.data.gridOption.dataSource.loadParams,
                }
              )
              .then((result) => {
                try {
                  if (!result.data.success) {
                    throw "konfigurasi form tidak ditemukan";
                  }
                  let data = result.data.data;
                  let categories = data.map(
                    (x) => x.nama
                  );

                  this.chartWaduk.xAxis.categories =  categories;
                  this.chartSitu.xAxis.categories =  categories;
                  this.chartDanau.xAxis.categories =  categories;
                  this.chartEmbung.xAxis.categories =  categories;

                  let waduk = {
                    "tidak_operasi" : [],
                    "operasi" : [],
                  };
                  let situ = {
                    "tidak_operasi" : [],
                    "operasi" : [],
                  };
                  let danau = {
                    "tidak_operasi" : [],
                    "operasi" : [],
                  };
                  let embung = {
                    "tidak_operasi" : [],
                    "operasi" : [],
                  };
                  // vm.chartSungai.series[0].data
                  console.log("tampungan",data);
                  data.forEach((value, index) => {

                    danau.operasi.push(parseInt(value.total_tampungan_danau_operasi))
                    danau.tidak_operasi.push((parseInt(value.total_tampungan_danau_tidak_operasi) + parseInt(value.total_tampungan_danau_tidak_aktif)))

                    embung.operasi.push(parseInt(value.total_tampungan_embung_operasi))
                    embung.tidak_operasi.push((parseInt(value.total_tampungan_embung_tidak_operasi)+ parseInt(value.total_tampungan_embung_tidak_aktif)))

                    situ.operasi.push(parseInt(value.total_tampungan_situ_operasi))
                    situ.tidak_operasi.push((parseInt(value.total_tampungan_situ_tidak_operasi) + parseInt(value.total_tampungan_situ_tidak_aktif)))

                    waduk.operasi.push(parseInt(value.total_tampungan_waduk_operasi))
                    waduk.tidak_operasi.push((parseInt(value.total_tampungan_waduk_operasi) + parseInt(value.total_tampungan_waduk_tidak_aktif)))

                  });

                  console.log("waduk",waduk);
                  console.log("danau",danau);
                  console.log("situ",situ);
                  console.log("embung",embung);
                  vm.chartWaduk.series[0].data = waduk.tidak_operasi;
                  vm.chartWaduk.series[1].data = waduk.operasi;

                  vm.chartDanau.series[0].data = danau.tidak_operasi;
                  vm.chartDanau.series[1].data = danau.operasi;

                  vm.chartSitu.series[0].data = situ.tidak_operasi;
                  vm.chartSitu.series[1].data = situ.operasi;

                  vm.chartEmbung.series[0].data = embung.tidak_operasi;
                  vm.chartEmbung.series[1].data = embung.operasi;
                } catch (e) {
                  vm.msgShow(e, "error");
                }
              });
            // vm.gridTampunganConfigInit(resp.data.data);
          } catch (e) {
            vm.msgShow(e, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 404) {
            vm.msgShow("konfigurasi form tidak ditemukan", "error");
          } else if (error.response.data !== undefined) {
            vm.msgShow(error.response.data.message, "error");
          }
        });
    },
    gridTampunganConfigInit(config) {
      const vm = this;
      vm.grid = vm.$refs[vm.gridTampunganName].instance;
      vm.gridConfig = config;
      vm.grid.option(config.gridOption);

      if (
        config.gridOption !== undefined &&
        config.gridOption.dataSource !== undefined
      ) {
        vm.grid.option(
          "dataSource.store",
          vm.$aspnet.createStore({
            key: config.gridOption.dataSource.key,
            loadUrl: `${process.env.VUE_APP_API_URL}/${config.gridOption.dataSource.loadUrl}`,
            loadMethod:
              config.gridOption.dataSource.loadMethod !== undefined
                ? config.gridOption.dataSource.loadMethod
                : "GET",
            loadParams: config.gridOption.dataSource.loadParams,
            onBeforeSend(operation, ajaxSettings) {
              if (operation === "load") {
                ajaxSettings.headers = {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                };
                ajaxSettings.data = {
                  data: JSON.stringify(ajaxSettings.data),
                };
              }
            },
          })
        );
      }
    },
    gridMataAirMount() {
      const vm = this;
      axiosIns
        .get(`${vm.formSettingRouteReq + vm.pageMataAirName}.json`, {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        })
        .then((resp) => {
          try {
            // console.log("resp", resp);
            if (!resp.data.success) {
              throw "konfigurasi form tidak ditemukan";
            }

            //get data for chart
            axiosIns
              .post(
                `${process.env.VUE_APP_API_URL}/${resp.data.data.gridOption.dataSource.loadUrl}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  data: resp.data.data.gridOption.dataSource.loadParams,
                }
              )
              .then((result) => {
                try {
                  if (!result.data.success) {
                    throw "konfigurasi form tidak ditemukan";
                  }
                  let data = result.data.data;
                  this.chartMataAir.xAxis.categories = data.map(
                    (x) => x.nama
                  );
                  let tidak_operasi = [];
                  let operasi = [];
                  // vm.chartSungai.series[0].data

                  data.forEach((value, index) => {
                    tidak_operasi.push(parseInt(value.total_mata_air_tidak_operasi));
                    operasi.push(parseInt(value.total_mata_air_operasi));
                  });

                  vm.chartMataAir.series[0].data = tidak_operasi;
                  vm.chartMataAir.series[1].data = operasi;
                } catch (e) {
                  vm.msgShow(e, "error");
                }
              });
            // vm.gridMataAirConfigInit(resp.data.data);
          } catch (e) {
            vm.msgShow(e, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 404) {
            vm.msgShow("konfigurasi form tidak ditemukan", "error");
          } else if (error.response.data !== undefined) {
            vm.msgShow(error.response.data.message, "error");
          }
        });
    },
    gridMataAirConfigInit(config) {
      const vm = this;
      vm.grid = vm.$refs[vm.gridMataAirName].instance;
      vm.gridConfig = config;
      vm.grid.option(config.gridOption);

      if (
        config.gridOption !== undefined &&
        config.gridOption.dataSource !== undefined
      ) {
        vm.grid.option(
          "dataSource.store",
          vm.$aspnet.createStore({
            key: config.gridOption.dataSource.key,
            loadUrl: `${process.env.VUE_APP_API_URL}/${config.gridOption.dataSource.loadUrl}`,
            loadMethod:
              config.gridOption.dataSource.loadMethod !== undefined
                ? config.gridOption.dataSource.loadMethod
                : "GET",
            loadParams: config.gridOption.dataSource.loadParams,
            onBeforeSend(operation, ajaxSettings) {
              if (operation === "load") {
                ajaxSettings.headers = {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                };
                ajaxSettings.data = {
                  data: JSON.stringify(ajaxSettings.data),
                };
              }
            },
          })
        );
      }
    },
    gridSumurMount() {
      const vm = this;
      axiosIns
        .get(`${vm.formSettingRouteReq + vm.pageSumurName}.json`, {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        })
        .then((resp) => {
          try {
            // console.log("resp", resp);
            if (!resp.data.success) {
              throw "konfigurasi form tidak ditemukan";
            }

            //get data for chart
            axiosIns
              .post(
                `${process.env.VUE_APP_API_URL}/${resp.data.data.gridOption.dataSource.loadUrl}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  data: resp.data.data.gridOption.dataSource.loadParams,
                }
              )
              .then((result) => {
                try {
                  if (!result.data.success) {
                    throw "konfigurasi form tidak ditemukan";
                  }
                  let data = result.data.data;
                  let categories = data.map(
                    (x) => x.nama
                  );
                  // console.log("sumur air", data);

                  this.chartSumur.xAxis.categories =  categories;
                  this.chartSumurAirBaku.xAxis.categories =  categories;
                  this.chartSumurJIAT.xAxis.categories =  categories;
                  let total_air_baku = [];
                  let total_jiat = [];
                  let jiat = {
                    "tidak_aktif" : [],
                    "tidak_operasi" : [],
                    "operasi" : [],
                  };
                  let air_baku = {
                    "tidak_aktif" : [],
                    "tidak_operasi" : [],
                    "operasi" : [],
                  };
                  // vm.chartSungai.series[0].data

                  data.forEach((value, index) => {
                    total_air_baku.push(parseInt(value.total_sumur_air_baku));
                    total_jiat.push(parseInt(value.total_sumur_jiat));

                    jiat.operasi.push(parseInt(value.total_sumur_jiat_operasi))
                    jiat.tidak_operasi.push(parseInt(value.total_sumur_jiat_tidak_operasi))
                    jiat.tidak_aktif.push(parseInt(value.total_sumur_jiat_tidak_aktif))

                    air_baku.operasi.push(parseInt(value.total_sumur_air_baku_operasi))
                    air_baku.tidak_operasi.push(parseInt(value.total_sumur_air_baku_tidak_operasi))
                    air_baku.tidak_aktif.push(parseInt(value.total_sumur_air_baku_tidak_aktif))
                  });

                  vm.chartSumur.series[0].data = total_air_baku;
                  vm.chartSumur.series[1].data = total_jiat;

                  vm.chartSumurJIAT.series[0].data = jiat.tidak_aktif;
                  vm.chartSumurJIAT.series[1].data = jiat.tidak_operasi;
                  vm.chartSumurJIAT.series[2].data = jiat.operasi;

                  vm.chartSumurAirBaku.series[0].data = air_baku.tidak_aktif;
                  vm.chartSumurAirBaku.series[1].data = air_baku.tidak_operasi;
                  vm.chartSumurAirBaku.series[2].data = air_baku.operasi;
                } catch (e) {
                  vm.msgShow(e, "error");
                }
              });
            // vm.gridSumurConfigInit(resp.data.data);
          } catch (e) {
            vm.msgShow(e, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 404) {
            vm.msgShow("konfigurasi form tidak ditemukan", "error");
          } else if (error.response.data !== undefined) {
            vm.msgShow(error.response.data.message, "error");
          }
        });
    },
    gridSumurConfigInit(config) {
      const vm = this;
      vm.grid = vm.$refs[vm.gridSumurName].instance;
      vm.gridConfig = config;
      vm.grid.option(config.gridOption);

      if (
        config.gridOption !== undefined &&
        config.gridOption.dataSource !== undefined
      ) {
        vm.grid.option(
          "dataSource.store",
          vm.$aspnet.createStore({
            key: config.gridOption.dataSource.key,
            loadUrl: `${process.env.VUE_APP_API_URL}/${config.gridOption.dataSource.loadUrl}`,
            loadMethod:
              config.gridOption.dataSource.loadMethod !== undefined
                ? config.gridOption.dataSource.loadMethod
                : "GET",
            loadParams: config.gridOption.dataSource.loadParams,
            onBeforeSend(operation, ajaxSettings) {
              if (operation === "load") {
                ajaxSettings.headers = {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                };
                ajaxSettings.data = {
                  data: JSON.stringify(ajaxSettings.data),
                };
              }
            },
          })
        );
      }
    },
  },
};
</script>

<style>
.home .dx-datagrid .dx-column-lines > td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
